import { template as template_85589bc3a366423e8cc6ed40dd72e453 } from "@ember/template-compiler";
const FKLabel = template_85589bc3a366423e8cc6ed40dd72e453(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
