import { template as template_6e9cbc85619d44d29598fa4cfc341980 } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
const ActionList = template_6e9cbc85619d44d29598fa4cfc341980(`
  {{#if @postNumbers}}
    <div class="post-actions" ...attributes>
      {{icon @icon}}
      {{#each @postNumbers as |postNumber|}}
        <a href="{{@topic.url}}/{{postNumber}}">#{{postNumber}}</a>
      {{/each}}
    </div>
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActionList;
