import { template as template_5f3d21c0a0ba4044b936c47a0da3720c } from "@ember/template-compiler";
import concatClass from "discourse/helpers/concat-class";
const FKSection = template_5f3d21c0a0ba4044b936c47a0da3720c(`
  <div class={{concatClass "form-kit__section" @class}} ...attributes>
    {{#if @title}}
      <h2 class="form-kit__section-title">{{@title}}</h2>
    {{/if}}

    {{#if @subtitle}}
      <span class="form-kit__section-subtitle">{{@subtitle}}</span>
    {{/if}}

    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKSection;
