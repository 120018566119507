import { template as template_79fa1a7d0fc545969a0d802a43adc098 } from "@ember/template-compiler";
const FKControlMenuContainer = template_79fa1a7d0fc545969a0d802a43adc098(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
