import { template as template_e5c5ff6c4e914750a4b2fc8178f9f51a } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_e5c5ff6c4e914750a4b2fc8178f9f51a(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
